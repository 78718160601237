import { PageProps } from "gatsby";
import React from "react";
import App from "../components/App";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import Box from "../elements/Box";
import Text from "../elements/Text";


const TermsAndConditions = ({ location }: PageProps) => {
    const data = [
        {
            'title': 'Purpose',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following:</p>
                    <ol>
                        <li>The personal data we will collect;</li>
                        <li>Use of collected data;</li>
                        <li>Who has access to the data collected; and</li>
                        <li>The rights of Site users.</li>
                    </ol>
                    <p>This Privacy Policy applies in addition to the terms and conditions of our Site.</p>
                </Box>
        },
        {
            'title': 'Consent',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>By using our Site users agree that they consent to:</p>
                    <ol>
                        <li>The conditions set out in this Privacy Policy; and</li>
                        <li>The collection, use, and retention of the data listed in this Privacy Policy.</li>
                    </ol>
                    <p>
                        Personal Data We Collect<br/>
                        We only collect data that helps us achieve the purpose set out in this Privacy Policy.
                        We will not collect any additional data beyond the data listed below without notifying you first.
                    </p>
                </Box>
        },
        {
            'title': 'Data Collected Automatically',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>When you visit and use our Site, we may automatically collect and store the following information:</p>
                    <ol>
                        <li>IP address;</li>
                        <li>Location;</li>
                        <li>Hardware and software details;</li>
                        <li>Clicked links; and</li>
                        <li>Content viewed.</li>
                    </ol>
                </Box>
        },
        {
            'title': 'How We Use Personal Data',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        Data collected on our Site will only be used for the purposes specified in this Privacy Policy
                        or indicated on the relevant pages of our Site. We will not use your data beyond what we
                        disclose in this Privacy Policy.
                    </p>
                    <p>The data we collect automatically is used for the following purposes:</p>
                    <ol>
                        <li>Statistics</li>
                        <li>Website performance</li>
                    </ol>
                </Box>
        },
        {
            'title': 'Who We Share Personal Data With',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>Employees</p>
                    <p>
                        We may disclose user data to any member of our organisation who reasonably needs access to user
                        data to achieve the purposes set out in this Privacy Policy.
                    </p>
                </Box>
        },
        {
            'title': 'Other Disclosures',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>We will not sell or share your data with other third parties, except in the following cases:</p>
                    <ol>
                        <li>If the law requires it;</li>
                        <li>If it is required for any legal proceeding;</li>
                        <li>To prove or protect our legal rights; and</li>
                        <li>To buyers or potential buyers of this company in the event that we seek to sell the company.</li>
                    </ol>
                    <p>
                        If you follow hyperlinks from our Site to another Site, please note that we are not responsible
                        for and have no control over their privacy policies and practices.
                    </p>
                </Box>
        },
        {
            'title': 'How Long We Store Personal Data',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>User data will be stored until the purpose the data was collected for has been achieved.</p>
                    <p>You will be notified if your data is kept for longer than this period.</p>
                </Box>
        },
        {
            'title': 'How We Protect Your Personal Data',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        in order to protect your security, we use the strongest available browser encryption and store
                        all of our data on servers in secure facilities. All data is only accessible to our employees.
                        Our employees are bound by strict confidentiality agreements and a breach of this agreement
                        would result in the employee's termination.
                    </p>
                    <p>
                        While we take all reasonable precautions to ensure that user data is secure and that users are
                        protected, there always remains the risk of harm. The Internet as a whole can be insecure at
                        times and therefore we are unable to guarantee the security of user data beyond what is
                        reasonably practical.
                    </p>
                </Box>
        },
        {
            'title': 'Children',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>We do not knowingly collect or use personal data from children under 16 years of age. If we learn that we have collected personal data from a child under 16 years of age, the personal data will be deleted as soon as possible. If a child under 16 years of age has provided us with personal data their parent or guardian may contact our privacy officer.</p>
                </Box>
        },
        {
            'title': 'How to Access, Modify, Delete, or Challenge the Data Collected',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        If you would like to know if we have collected your personal data, how we have used your
                        personal data, if we have disclosed your personal data and to who we disclosed your personal
                        data, or if you would like your data to be deleted or modified in any way, please contact us here:
                        <a href={"mailto:contact@acidgreen.com.au"}>
                            contact@acidgreen.com.au
                        </a>
                    </p>
                </Box>
        },
        {
            'title': 'Opt-Out',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        Any individual has the right to opt-out of email communication from us at any time. Each email
                        sent by acidgreen contains a link that allows the individual to permanently opt-out of
                        receiving emails from acidgreen. Additionally the email management allows the individual to
                        manage the type of communication they receive via subscription management.
                    </p>
                </Box>
        },
        {
            'title': 'Modifications',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        This Privacy Policy may be amended from time to time in order to maintain compliance with the
                        Austalian law and to reflect any changes to our data collection process. When we amend this
                        Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy.
                        We recommend that our users periodically review our Privacy Policy to ensure that they
                        are notified of any updates. If necessary, we may notify users by email of changes to this
                        Privacy Policy.
                    </p>
                </Box>
        },
        {
            'title': 'Contact Information',
            'content':
                <Box margin="0 0 0 1rem">
                    <p>
                        If you have any questions, concerns or complaints, you can contact us here:
                        <a href={"mailto:contact@acidgreen.com.au"}>
                            contact@acidgreen.com.au
                        </a>
                    </p>
                </Box>
        }

    ];

    return (
        <App>
            <SEO
                title={"Privacy Policy"}
                description={"Acidgreen website Privacy Policy"}
                url={location.href}
            />
            <Hero heading="Privacy Policy"></Hero>
            <Box
                display="flex"
                flexDirection={["column", "column", "row"]}
                maxWidth="1440px"
                width={["90%", "90%", "80%"]}
                margin={["2rem auto", "2rem auto", "4.5rem auto"]}
                position="relative"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width={["auto", "auto", "100%"]}
                    margin={["0 auto 1rem auto", "auto", "auto auto auto 2rem"]}
                >
                    {data?.map((section, index) => (
                        <Box margin="0 0 2rem 0">
                            <Text
                                as="h2"
                                margin="0"
                                key={section.title}
                                fontWeight="normal"
                                fontSize="32px"
                                lineHeight="42px"
                            >
                                {section.title}
                            </Text>
                            { section.content }
                        </Box>
                    ))}
                </Box>
            </Box>
        </App>
    );
};

export default TermsAndConditions;
